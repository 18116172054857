<!--
	This is the documents page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		<h1>Your Building Public Documents</h1>
		<!-- Documents Table -->
		<a-row :gutter="24" type="flex">

			<!-- Documents Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Documents Table Card -->
				<CardDocumentsTable
					:columns="table1Columns"
				></CardDocumentsTable>
				<!-- / Documents Table Card -->

			</a-col>
			<!-- / Documents Table Column -->

		</a-row>
		<!-- / Documents Table -->

	</div>
</template>

<script>

	// "Documents" table component.
	import CardDocumentsTable from '../components/Cards/CardDocumentsTable.vue';
	import { mapActions } from 'vuex'
	import { mapState } from 'vuex'

	// "Documents" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'Document Name',
			scopedSlots: { customRender: 'name' },
			sorter: (a, b) => a.name.localeCompare(b.name), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter

		},
		{
			title: 'Type',
			dataIndex: 'type',
			class: 'font-semibold text-muted text-sm',
			sorter: (a, b) => a.type.localeCompare(b.type), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},


		{
			title: 'Details',
			dataIndex: 'details',
			class: 'font-semibold text-muted text-sm details',
			sorter: (a, b) => a.details.localeCompare(b.details), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Date Uploaded',
			dataIndex: 'created_timestamp',
			class: 'font-semibold text-muted text-sm',
			sorter: (a, b) => new Date(a.created_timestamp) - new Date(b.created_timestamp), // assuming the 'date' field contains a Date object
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},


		{
			title: '',
			scopedSlots: { customRender: 'actionsBtn' },
		},
	];

	// "Documents" table list of rows and their properties.




	export default ({
		components: {
	CardDocumentsTable,
},
		data() {
			return {

				// Associating "Documents" table columns with its corresponding property.
				table1Columns: table1Columns,


			}
		},
		async mounted() {
			window.analytics.page('Documents');
		},
	})

</script>
 
<style lang="scss">
.details {
    white-space: pre-wrap;
}
</style>
